<template>
  <div class="" style="height: 100%">
    <b-row class="h-100">
      <b-col md="7" class="h-100">
        <b-card no-body class="bg-colorBlue">
          <b-card-header class="py-5">
            <b-row class="d-flex align-items-center">
              <b-col md="9">
                <h4 class="font-weight-bolder text-light m-0 p-0">
                  Welcome
                  <span class="text-colorGreen">
                    DR. {{ this.getLoggedInUser.full_name }}</span
                  >
                </h4>
                <p class="font-weight-bolder text-light pt-1">
                  {{ this.getLoggedInUser.doctor_data.intro }}
                </p>
                <p class="font-weight-bolder text-light pt-1">
                  {{ this.getLoggedInUser.doctor_data.about }}
                </p>
              </b-col>
              <b-col md="3">
                <div>
                  <b-img
                    :src="this.getLoggedInUser.profile_image"
                    class="img-fluid"
                    alt="image"
                  ></b-img></div
              ></b-col>
            </b-row>
          </b-card-header>
          <b-card-body> </b-card-body>
        </b-card>
        <b-row class="py-1">
          <b-col md="4">
            <b-card no body>
              <div class="p-2">
                <h5 class="font-weight-bolder text-colorBlue">
                  Procedure Last Week
                </h5>
                <h6 class="font-weight-bolder text-colorGreen">24</h6>
              </div></b-card
            >
          </b-col>
          <b-col md="4">
            <b-card no body>
              <div class="p-2">
                <h5 class="font-weight-bolder text-colorBlue">
                  Procedure Last Week
                </h5>
                <h6 class="font-weight-bolder text-colorGreen">24</h6>
              </div></b-card
            >
          </b-col>
          <b-col md="4">
            <b-card no body>
              <div class="p-2">
                <h5 class="font-weight-bolder text-colorBlue">
                  Procedure Last Week
                </h5>
                <h6 class="font-weight-bolder text-colorGreen">24</h6>
              </div></b-card
            >
          </b-col>
        </b-row>
        <b-card no-body class="">
          <b-card-header>
            <div>
              <h4 class="font-weight-bolder text-colorBlue m-0 p-0">
                Patient List
              </h4>
            </div>
          </b-card-header>
          <b-card-body>
            <b-table
              stacked="md"
              :fields="fieldPatient"
              :items="patient"
              thead-class="tableHeadColor"
              small
              rounded
            >
              <!--procedures -->
              <template #head()="data">
                <div class="">
                  <span class="font-weight-bolder text-colorBlue">{{
                    data.label
                  }}</span>
                </div>
              </template>

              <template #cell(patient_id)="row">
                <div class="py-1 text-colorBlue font-weight-bolder">
                  {{ row.item.patient_id ? row.item.patient_id : "" }}
                </div>
              </template>
              <template #cell(patient_name)="row">
                <div class="py-1 text-colorBlue font-weight-bolder">
                  {{ row.item.patient_name ? row.item.patient_name : "" }}
                </div>
              </template>
              <template #cell(phone_no)="row">
                <div class="py-1 text-colorBlue font-weight-bolder">
                  {{ row.item.phone_no ? row.item.phone_no : "" }}
                </div>
              </template>
              <template #cell(email)="row">
                <div class="py-1 text-colorBlue font-weight-bolder">
                  {{ row.item.email ? row.item.email : "" }}
                </div>
              </template>
              <template #cell(manage)="">
                <div class="">
                  <b-button pill size="sm" variant="colorBlue">
                    Send Response
                  </b-button>
                </div>
              </template>
            </b-table>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col md="5" class="h-100">
        <b-row class="h-100">
          <b-col md="12" class="h-100">
            <b-card no-body class="bg-colorBlue h-100">
              <b-card-header>
                <div>
                  <h4 class="font-weight-bolder text-colorGreen m-0 p-0">
                    Procedures List
                  </h4>
                </div>
              </b-card-header>
              <b-card-body>
                <b-table
                  stacked="md"
                  :fields="fields"
                  :items="procedure"
                  thead-class="tableHeadColor"
                  small
                  rounded
                >
                  <template #head()="data">
                    <div class="">
                      <span class="font-weight-bolder text-colorGreen">{{
                        data.label
                      }}</span>
                    </div>
                  </template>
                  <template #cell(name)="row">
                    <div class="py-1 text-light">
                      {{ row.item.name ? row.item.name : "" }}
                    </div>
                  </template>
                  <template #cell(duration)="row">
                    <div class="py-1 text-light">
                      {{ row.item.duration ? row.item.duration : "" }} Day
                    </div>
                  </template>
                  <template #cell(amount)="row">
                    <div class="py-1 text-colorGreen font-weight-bolder">
                      $ {{ row.item.amount ? row.item.amount : "" }}
                    </div>
                  </template>

                  <template #cell(manage)="">
                    <div class="">
                      <b-button pill size="sm" variant="colorBlue">
                        Pay Now
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import util from "@/util.js";

export default {
  data() {
    return {
      fields: [
        { key: "name", label: "Procedure Name" },
        {
          key: "duration",
          label: "Duration",
        },
        { key: "amount", label: "Price" },
      ],

      fieldPatient: [
        { key: "patient_id", label: "Patient ID" },
        { key: "patient_name", label: "Patient Name" },
        { key: "phone_no", label: "Phone Number" },
        { key: "email", label: "Email" },
        { key: "manage", label: "" },
      ],
      patient: [
        {
          patient_id: 4,
          patient_name: "rabada",
          phone_no: "0315040844",
          email: "example@gmail.com",
        },
      ],
      procedure: [],
      currentPage: 1,
    };
  },
  mixins: [util],
  async mounted() {
    await this.fetchProcedure();
    await this.fetchDoctorOrder();
  },

  methods: {
    ...mapActions({
      initiatePayment: "appData/initiatePayment",
      getProcedures: "appData/getProcedures",
      getDoctorOrder: "appData/getDoctorOrder",
    }),
    clicked() {},
    async fetchProcedure() {
      try {
        let tempParams = {
          doctor: this.getLoggedInUser.doctor_data.id,
          pageNumber: this.currentPage,
          id: null,
          hospital: null,
          department: null,
        };
        const res = await this.getProcedures(tempParams);
        this.procedure = res.data.results;
      } catch (error) {
        this.displayError(error);
      }
    },
    async fetchDoctorOrder() {
      try {
        let tempParams = {
          pageNumber: this.currentPage,
        };
        const res = await this.getDoctorOrder(tempParams);
        this.patient = res.data.results;
      } catch (error) {
        this.displayError(error);
      }
    },
  },
  computed: {
    ...mapGetters({
      hasRole: "appData/hasRole",
      getLoggedInUser: "appData/getLoggedInUser",
    }),
  },
};
</script>

<style>
.tableHeadColor {
  background-color: red !important;
}
</style>
